import { type ReactNode } from 'react'
import { cn } from '~/utils/misc.tsx'

export const MainContainer = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <main
      className={cn('mx-auto max-w-[1440px] px-3 pb-2 pt-2 sm:px-8', className)}
    >
      {children}
    </main>
  )
}
